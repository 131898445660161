/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@mobiscroll/angular/dist/css/mobiscroll.scss";
$mbsc-ios-accent: #727272;

.popover-content.sc-ion-popover-md {
  border-radius: 0px !important;
}
.no-data-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #4f5673;
  font-size: 14px;
  text-align: center;
}
.red-dot {
  height: 6px;
  width: 6px;
  background-color: var(--ion-badge-bg-color);
  border-radius: 50%;
  display: inline-block;
  position: initial;
  color: var(--ion-badge-bg-color);
  margin-top: -28px;
  // margin-left: 9px;
}
ion-badge {
  border-radius: 5px;
  color: var(--ion-background-color);
  background-color: var(--ion-badge-bg-color);
  font-size: 11px !important;
  font-weight: 700px !important;
}
.status-text {
  text-transform: capitalize;
  font-size: 16px !important;
}
.custom-light-text {
  font-size: 16px;
  color: var(--ion-light-text) !important;
}
[custom-light-text14] {
  font-size: 14px;
  color: var(--ion-light-text) !important;
}
.custom-light-textsmaller {
  font-size: smaller !important;
  color: var(--ion-light-text) !important;
}
.normal-text-color {
  font-size: 12px;
  color: var(--ion-color-step-400) !important;
}
.running_highlight {
  font-size: 14px !important;
  color: var(--ion-header-text) !important;
}
.custom-label-color {
  font-size: 13px !important;
  color: var(--ion-color-label) !important;
}
.header-right-btn-position {
  margin-right: 16px !important;
}
.ios body {
  ion-header {
    ion-title {
      font-size: 20px;
      text-align: center;
      padding: unset;
    }
    ion-buttons {
      height: 50px;
    }
    ion-item {
      box-shadow: 0 1px 5px 2px #c7c7c7;

      ion-icon {
        padding-right: 10px;
      }
    }
    ion-searchbar {
      height: 50px;
      ion-icon {
        padding-left: 10px !important;
      }
      input {
        left: 20px;
        position: absolute;
      }
    }
  }

  ion-popover {
    .popover-arrow {
      display: none;
    }
    .popover-content {
      border-radius: 0 !important;
    }
    app-filter-list ion-list-header {
      align-items: center;
      font-size: 16px;
      padding-left: 15px;
      font-weight: 300;
    }
  }
}
ion-header {
  ion-toolbar {
    --background: #f6f6ff !important;
    --border-width: 0 !important;
    // height: 56px;
  }
  ion-title {
    --color: var(--ion-header-text) !important;
    font-weight: 300 !important;
    padding-left: 8px;
  }
  ion-row {
    width: 100%;
  }
  ion-item {
    --padding-start: 0px !important;
    --border: none !important;
    --border-color: #dedede !important;
  }
  ion-icon {
    font-size: 25px;
    color: var(--ion-header-text) !important;
  }
  ion-menu-button {
    color: var(--ion-header-text) !important;
  }
  ion-buttons {
    margin-left: 8px;
    color: var(--ion-header-text) !important;
  }
}
.header-details {
  color: var(--ion-header-text) !important;
  padding: 12px !important;
  background: var(--ion-header-light-background) !important;
  text-align: center !important;
  width: 100% !important;
  z-index: 10;
}
ion-searchbar {
  --border: none !important;
  --box-shadow: none !important;
  --background: transparent !important;
  padding: 0 0 0 5px !important;
  color: var(--ion-color-step-400) !important;

  ion-icon {
    --padding-start: 0px !important;
    font-size: 20px !important;
  }
}
ion-list-header {
  --background: #f9f9f9 !important;
  --color: #757575 !important;
  font-size: 15px;
  align-items: center;
  font-weight: 500;
}
.status-filter {
  ion-label {
    color: #727272 !important;
  }
  ion-icon {
    padding-left: 20px;
    padding-right: 10px;
    font-size: 25px;
  }
}
.font-bold {
  color: #727272;
  font-weight: 600;
  padding: 0;
  font-size: 16px;
}
.filter-arrow-icon {
  font-size: 15px;
  vertical-align: text-bottom;
}
[my-overlay] {
  height: 100%;
  cursor: pointer;
  width: 100%;
  overflow: auto;
  position: fixed;
  z-index: 60;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  font-size: 2.2rem;
  background-color: rgba(114, 114, 114, 0.1);
}
.amber-status {
  color: #ff8f00 !important;
}
.blue-status {
  color: #5a6388 !important;
}
.green-status {
  color: #2e7d32 !important;
}

.red-status {
  color: #c62828 !important;
}

//its required for inline class
.amber-status1 {
  color: #ff8f00 !important;
}
.green-status1 {
  color: #2e7d32 !important;
}
.red-status1 {
  color: #c62828 !important;
}

// font sizes
[fontSize1] {
  font-size: 16px !important;
}
[fontSize2] {
  font-size: 14px !important;
}
[fontSize3] {
  font-size: 15px !important;
}
[fontSize4] {
  font-size: 12px !important;
}
[fontSize5] {
  font-size: 18px !important;
}
[fontSize6] {
  font-size: 13px !important;
}
[fontSize7] {
  font-size: 10px !important;
}
[fontSize8] {
  font-size: 20px !important;
}
[fontSize32] {
  font-size: 32px !important;
}
[fontSize11] {
  font-size: 11px !important;
}
[fontSize28] {
  font-size: 28px !important;
}
// padding styles
[px-5] {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
[py-5] {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

[px-10] {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

[px-15] {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
[py-10] {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
[py-25] {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
[p-0] {
  padding: 0 !important;
}
[py-0] {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
[pb-0] {
  padding-bottom: 0 !important;
}

[bottom-10] {
  bottom: 10px;
}
[pl-15] {
  padding-left: 15px !important;
}

[pl-5] {
  padding-left: 5px !important;
}
[pr-15] {
  padding-right: 15px !important;
}

[pl-10] {
  padding-left: 10px !important;
}
[pr-5] {
  padding-right: 5px !important;
}

[pr-10] {
  padding-right: 10px !important;
}
[padding-5] {
  padding: 5px !important;
}
// margin styles
[mt-5] {
  margin-top: 5px !important;
}
[mt-0] {
  margin-top: 0px !important;
}
[mt-10] {
  margin-top: 10px !important;
}
[no-border] {
  border: none !important;
}
[text-right] {
  text-align: right;
}
[text-capitalize] {
  text-transform: capitalize !important;
}
[text-uppercase] {
  text-transform: uppercase !important;
}
[m-auto] {
  margin: auto !important;
}

[ml-auto] {
  margin-left: auto !important;
}
[m-minline] {
  margin: 0;
  margin-inline: 0;
}

[d-none] {
  display: none !important;
}
[colorPrimay] {
  color: var(--ion-color-primary) !important;
}
.download-icon {
  width: 35px;
  background-color: transparent !important;
  vertical-align: middle;
}
.toast-custom-wrapper {
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  z-index: 10;
  display: block;
  width: 100%;
  max-width: 700px;
  --background: #333;
  --color: #f3f3f3;
}

[input-error] {
  padding: 5px 0 0 15px !important;
  color: var(--ion-color-danger-dark) !important;
  font-size: 12px !important;
}
// For ion-footer button
ion-footer {
  ion-row > ion-col:nth-child(1) {
    margin-left: -1px !important;
  }
  [custom-submit-button] {
    height: 50px !important;
    letter-spacing: normal;
    --background: var(--ion-custom-button-bg) !important;
    color: var(--ion-header-text) !important;
    font-weight: bold !important;
    font-size: small !important;
    margin-bottom: -1px;
  }

  position: initial !important;
}

// For none ion-footer button
[custom-button] {
  --background: #dadaf6 !important;
  letter-spacing: normal !important;
  --color: var(--ion-header-text) !important;
  border-radius: 4px !important;
  height: 45px !important;
  font-weight: bold !important;
  font-size: small !important;
  font-family: sans-serif;
}
// for buuton line spacing
[custom-button-spacing] {
  height: 45px !important;
  margin: 0 auto !important;
}
[width-20] {
  max-width: 80px !important;
}
[width-38] {
  max-width: 38% !important;
}
[width-62] {
  max-width: 62% !important;
}

[no-padding] {
  padding: 0% !important;
}
[custom-listborder] {
  .list-ios > .item-block:first-child {
    border-top: none !important;
  }
  .list-ios > .item-block:last-child {
    border-bottom: none !important;
  }
  .list-md .item-input:last-child {
    border-bottom: none !important;
  }
}
.forward-icon {
  font-size: 25px !important;
}
[margin-left-13] {
  margin-left: 13px !important;
}
// newly added new class for add taxes
.item-interactive {
  --highlight-background: var(--ion-primary-text) !important;
  bottom: -1px;
  --highlight-height: 1px;
}
.item-interactive.ion-untouched {
  --highlight-background: var(--ion-primary-text) !important;
  bottom: -1px;
  --highlight-height: 1px;
}
.item-interactive.ion-valid {
  --highlight-background: var(--ion-primary-text) !important;
  bottom: -1px;
  --highlight-height: 1px;
}
.item-interactive.ion-invalid {
  --highlight-height: 1px;
}
[background-img] {
  background-size: 100% 100% !important;
  height: 100% !important;
  background-color: var(--ion-light-background) !important;
  background-repeat: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  overflow: scroll !important;
}
[logo] {
  position: relative !important;
  top: 40px !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  line-height: 100px;
  width: 110px;
  height: 110px;
}
[desktop-view] {
  max-width: 650px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
[auth-content-height] {
  height: 70% !important;
}
[auth-input] {
  ion-input {
    ::placeholder {
      color: var(--ion-light-text) !important;
      font-weight: bold !important;
    }
    :-ms-input-placeholder {
      color: var(--ion-light-text) !important;
      font-weight: bold !important;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--ion-light-text) !important;
      font-weight: bold !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:active,
    input:-webkit-autofill:focus {
      background-color: #ffffff !important;
      color: #555 !important;
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
      -webkit-text-fill-color: #555555 !important;
    }
  }
  .item > ion-icon:first-child,
  .item-inner > ion-icon:first-child {
    text-align: center;
    min-width: 23px;
  }
}
.header-backgroud {
  background: var(--ion-color-lightheader) !important;
  text-transform: none !important;
}
.searchbar-input.sc-ion-searchbar-md {
  background-color: #fff !important;
  font-size: 14px;
}
[bottom-border-light] {
  border-bottom: 1px solid var(--ion-border-color) !important;
}
[bottom-border-verylight] {
  border-bottom: 1px solid var(--very-light-border) !important;
}

[border-sub] {
  border-top: 1px solid var(--ion-border-color) !important;
  border-bottom: 1px solid var(--ion-border-color) !important;
}
.liststyle {
  min-height: 50px !important;
  border-bottom: 1px solid var(--very-light-border) !important;
}
.card-backgroud {
  background: var(--ion-color-lightheader) !important;
}
.image-upload img {
  cursor: pointer !important;
  width: 100% !important;
  border-radius: 50% !important;
  height: 100% !important;
}
// for add  edit delete button theame start
.color-icon {
  color: var(--ion-color-icon) !important;
  margin-top: 5px !important;
}
.button-background-color {
  background-color: var(--ion-options-background-color) !important;
  border-right: 4px solid var(--ion-color-red) !important;
}
.button-background-color1 {
  background-color: var(--ion-options-background-color) !important;
  border-right: 4px solid var(--ion-primary-text) !important;
}
.button-background-color2 {
  background-color: var(--ion-options-background-color) !important;
  border-right: 4px solid var(--ion-color-amber) !important;
}
.button-background-color3 {
  background-color: var(--ion-options-background-color) !important;
  border-right: 4px solid var(--ion-options-background-color) !important;
}
.button-background-darkgreen {
  background-color: var(--ion-options-background-color) !important;
  border-right: 4px solid var( --ion-color-green) !important;
}
// end
.custom-setup-pin-modal .popover-content {
  width: 100%;
  bottom: 0;
  top: unset !important;
  left: 0 !important;
  transform-origin: bottom !important;
}
[text-center] {
  text-align: center !important;
}
.disabledPointer {
  pointer-events: none; // This makes it not clickable
  opacity: 0.6; // This grays it out to look disabled
}
.cursorNotAllowed:hover {
  cursor: not-allowed;
}
// for web/andriod left side menu design
.md .gravatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transperent;
  img {
    max-width: 75px;
    width: 75px;
    margin-top: 43px;
    border-radius: 50%;
    height: 75px;
    @media (min-width: 768px) {
      margin-right: 0px;
    }
  }
  [segment-min-width-50] {
    min-width: 50% !important;
  }
}
// for ios left side menu design
.ios .gravatar {
  img {
    max-width: 75px;
    width: 75px;
    margin-top: 0px;
    border-radius: 50%;
    height: 75px;
  }
}
// for web/andriod left side menu design
.md .custom-header-design {
  text-align: center;
  font-size: 16px;
  margin-top: 5px;
  margin-left: -10px;
  padding: 0px 30px;
  height: 55px;

  @media (min-width: 768px) {
    margin-left: -10px;
  }
}
// for ios left side menu design
.ios .custom-header-design {
  text-align: center;
  margin-top: 85px;
  font-size: 16px;
  padding: 0px 30px;
  height: 70px;
  transform: translate(0px, 30px);
}
[width-100] {
  width: 100% !important;
}
[width-40] {
  width: 40% !important;
}
[width-30] {
  width: 30% !important;
}
[width-5] {
  max-width: 5%;
}
[width-50] {
  max-width: 50% !important;
}
[width-30] {
  max-width: 30%;
}
[width-70] {
  max-width: 70%;
}
[width-75] {
  max-width: 75%;
}
[width-90] {
  max-width: 90%;
}
[width-95] {
  max-width: 95%;
}
[width-10] {
  max-width: 10%;
}
[width-15] {
  max-width: 15%;
}
[width-25] {
  max-width: 25%;
}
[width-20] {
  max-width: 20%;
}
[width-35] {
  max-width: 35%;
}
[width-85] {
  max-width: 85%;
}
[width-33] {
  max-width: 33%;
}
[width-60] {
  max-width: 60%;
}
[width-display] {
  display: flex;
  width: 100%;
}

/* use in html example:-
<div custom-spinner *ngIf="spinner">
<ion-spinner name="lines"></ion-spinner>
</div>*/
[custom-spinner] {
  position: absolute;
  top: 54%;
  left: 45%;
  z-index: 11;
  color: var(--ion-text-color2) !important;
  transform: translate(-50%, -50%);
}
[chat-icon-color] {
  color: var(--ion-color-icon) !important;
}
[button-bg-transparent] {
  background: transparent !important;
}
.alertCustomCss {
  .alert-message {
    color: var(--ion-color-danger-dark);
    text-align: justify;
    font-size: 13px;
    position: absolute;
    bottom: 27px;
  }
  .alert-title {
    font-size: 15px;
    color: var(--ion-color-dark) !important;
    font-weight: 500;
    text-align: justify;
  }
}
.fuelLastStnAlertCustomCss {
  .alert-message {
    color: var(--ion-color-dark) !important;
    text-align: end !important;
    // font-size: 13px !important;
    position: absolute !important;
    bottom: 72px !important;
    right: 20px !important;
  }
  .alert-title {
    font-size: 15px !important;
    color: var(--ion-color-dark) !important;
    font-weight: 500 !important;
    text-align: justify !important;
  }
}
.alertCustomCssPermit {
  .alert-message {
    color: var(--ion-color-danger-dark);
    text-align: justify;
    font-size: 13px;
    position: absolute;
    bottom: 27px;
  }
  .alert-title {
    font-size: 15px;
    color: var(--ion-color-dark) !important;
    font-weight: 500;
    text-align: justify;
  }
  .alert-input-group.sc-ion-alert-md {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.alert-title {
  font-size: 15px;
  color: var(--ion-color-dark-wallet) !important;
  font-weight: 400;
  text-align: justify;
}
.custom-placeholder {
  ::-webkit-input-placeholder {
    text-align: right;
    .list-style {
      min-height: 60px !important;
      padding-left: 0px !important;
      ion-item {
        padding-bottom: 7px !important;
        padding-top: 7px !important;
      }
    }
  }
}
.list-style {
  min-height: 60px !important;
  padding-left: 0px !important;
  ion-item {
    padding-bottom: 7px !important;
    padding-top: 7px !important;
  }
}
.list-item-color {
  color: #666565;
}
.iaco-code {
  white-space: normal;
  font-size: 14px;
}
.countryName {
  white-space: normal;
  font-size: 13px;
  color: var(--ion-light-text) !important;
}
ion-menu {
  border-right: 1px solid #dedede;
}
[header-list] {
  a {
    color: var(--ion-header-text) !important;
    white-space: normal !important;
  }
  p {
    color: var(--ion-light-text) !important;
    white-space: normal !important;
  }
  ion-icon {
    color: var(--ion-header-text) !important;
    font-size: 2rem !important;
    font-weight: bold !important;
  }
}
[div-font-color] {
  color: var(--ion-header-text) !important;
}
.clear {
  clear: both;
}
.content-middle {
  align-content: center;
  align-self: center;
  display: block;
  font-size: 27px !important;
}
h2 {
  margin: 2px 0;
  font-size: 14px;
  font-weight: normal;

  [icon-font-size-24] {
    font-size: 24px !important;
  }
}
.qq-p {
  margin: 0 -2px !important;
}
[qq-curency-div] {
  margin-top: 0px;
  padding-top: none !important;
}
[qq-display] {
  display: contents;
}
[qq-overflow] {
  overflow: visible;
}
[qq-float-r] {
  float: right;
}
[qq-icon] {
  margin-left: 15px;
  font-size: 1.8rem;
}
[qq-flyinout] {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: fixed;
  z-index: 60;
  background: var(--ion-color-primary-contrast);
}
[qq-selectcountry] {
  height: 100%;
  width: 100%;
  overflow: auto;
  position: fixed;
}
.no-data-mgs {
  position: absolute;
  left: 50%;
  bottom: 15%;
  transform: translate(-50%, -50%);
  color: #4f5673;
  font-size: 14px;
  text-align: center;
}
.currency-style {
  position: absolute;
  bottom: 40%;
}
.margin-center {
  margin: 0px 100px !important;
}
[padding-start-end] {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
[padding-start-0] {
  padding-inline-start: 0px;
}
[padding-start-2] {
  padding-inline-start: 2px;
  padding-inline-end: 2px;
}
[padding-end-2] {
  padding-inline-start: 2px;
  padding-inline-end: 2px;
}
[padding-start-22] {
  padding-inline-start: 22px !important;
}
[padding-start-10] {
  padding-inline-start: 10px !important;
}
.list-text {
  font-family: inherit !important;
  line-height: normal !important;
}
.qq-list-style {
  min-height: 0px !important;
  padding-left: 0px !important;
  ion-item {
    padding-bottom: 7px !important;
    padding-top: 7px !important;
  }
}
.prefix-l-p {
  position: absolute;
  left: 55%;
}
.second-header {
  font-size: 14px !important;
  white-space: large;
  color: var(--ion-light-text) !important;
}
[flyinout-footer-position] {
  position: absolute;
  bottom: 0px;
}
.custom-validity {
  color: var(--ion-color-red);
  font-size: 10px;
  margin: 0 0 0 0;
}
.image-person-align img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.second-header {
  font-size: 14px !important;
  white-space: large;
  color: var(--ion-light-text) !important;
}
[qq-no-taxes] {
  position: relative;
  left: 30%;
}
[qq-a-tag] {
  width: 70%;
  cursor: pointer;
}
// for vendor setup
.div-width {
  width: 100%;
  background: var(--ion-item-background);
  border-bottom: 0px solid var(--ion-border-color);
  margin-left: 10px;
}
.span-wiidth {
  background: #e6e6e6 !important;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  height: 37px;
  display: inline-block;
  border-radius: 20px;
}
.operational-width ion-textarea div textarea {
  border: none !important;
  resize: none !important;
  width: 100% !important;
  padding: 16px !important;
  color: var(--ion-light-text) !important;
  background: var(--ion-item-background) !important;
}
.remove-icon {
  margin-left: 10px !important;
  padding: 10px 5px 0px !important;
}
.currency {
  position: absolute;
  right: 15px !important;
  font-size: 11px;
  padding-top: 15px;
  color: var(--ion-color-medium);
  margin-top: 10px !important;
}
.currency-name {
  color: var(--ion-icon-color);
  margin-bottom: 0px !important;
  margin-left: 5px !important;
}
[margin-item-right] {
  margin: 0px 0px 0px 90px;
}
.tax-currency-name {
  color: var(--ion-icon-color);
  margin-bottom: 10px !important;
  margin-left: 5px !important;
}
.station-color {
  color: var(--ion-light-text) !important;
}
.login-submit-button-design {
  width: 100%;
  margin-top: 5px !important;
  margin-left: 0px !important;
  height: 37px !important;
  font-size: small !important;
}
.currencye {
  position: absolute;
  right: 8px !important;
  font-size: 12px;
  color: #949494;
}
.open-sidemenu-designn {
  cursor: pointer;
  align-content: center;
  align-self: center;
  display: block;
  text-align: right;
}
.currencie {
  font-size: 12px;
  color: #949494;
}

.aircraft-theame {
  color: var(--ion-primary-text) !important;
}
.country-title {
  margin: 0px 0px 0px 25px;
  width: auto;
  padding: 0px;
}
[custopm-placeholder] {
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--ion-text-color3);
    opacity: 0.7;
    /* Firefox */
    text-transform: capitalize !important;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--ion-text-color3);
    opacity: 0.7;
    /* Firefox */
    text-transform: capitalize !important;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--ion-text-color3);
    opacity: 0.7;
    /* Firefox */
    text-transform: capitalize !important;
  }
}
.custom-label-font {
  color: var(--ion-light-text);
}

ion-toggle {
  --background-checked: #8f96b2;
  --background: #dedede;
}
.alert-message {
  font-size: 14px !important;
}
ion-icon {
  --ionicon-stroke-width: 20px;
}
ion-buttons {
  ion-icon {
    --ionicon-stroke-width: 36px !important;
  }
}
[light-tab] {
  ion-tab-button {
    background: transparent;
  }
  ion-tab-bar {
    background: var(--ion-header-light-background) !important;
    box-shadow: 0px 1px 8px -3px var(--ion-color-medium);
    height: 55px;
    padding-top: 0px !important;
  }
}
[white-space-txt-notification] {
  display: contents;
  word-wrap: break-word;
  white-space: pre-line;
}
.tab-selected {
  font-size: var(--ion-text-size3);
  color: var(--ion-header-color);
}
ion-tab-button {
  max-width: 100% !important;
}
.custom-label-font {
  color: var(--ion-color-light);
  font-size: 14px !important;
  padding-left: 5px;
}
.selected {
  --background: var(--ion-color-step-1050);
}
[taxbtnCsv] {
  --background: none;
  --color: #4f5673;
  --box-shadow: none;
}
[taxIcon] {
  position: absolute;
  left: 357px;
  margin-bottom: 8px;
}
.list-background {
  // To apply background to individual active list component
  background: none !important;
  --background: var(--ion-header-light-background) !important;
}
.card-open {
  ion-item {
    --background: var(--ion-header-bg-color) !important;
  }
}
ion-searchbar {
  color: var(--ion-primary-text) !important;
}
.toast-button {
  color: none !important;
}
ion-list-header,
[custom-header-bg] {
  background: #f9f9f9 !important;
  margin-bottom: 0px !important;
}
[download-icon] {
  font-size: 27px !important; // for download icon
}
.filter-icon {
  margin-right: 0px !important;
  left: 0px;
}
.item-interactive.ion-invalid {
  --highlight-background: none !important;
}
:root {
  .custom-light-text14 {
    font-size: 14px !important;
    color: var(--ion-light-text) !important;
  }
}
[attachment-icon] {
  color: var(--ion-color-step-1250) !important;
  font-size: 28px;
  font-weight: 100 !important;
}
.attachment-button {
  //  for attachment icon and button in Flight planning and Fuel
  line-height: 0px !important;
  .fileUploadSize {
    font-size: 12px;
    padding-left: 10px !important;
    color: var(--ion-color-step-1250) !important;
  }
}
.close-icon {
  margin-bottom: 13px;
}
.close-btn {
  font-size: 1.5rem !important;
  padding-right: 16px !important;
  padding-bottom: 10px !important;
  margin-top: 10px !important;
  position: absolute;
  right: 5px;
}
.profileAlertCss {
  .alert-message {
    color: var(--ion-color-danger-dark);
    text-align: justify;
    font-size: 13px;
    position: absolute;
    bottom: 27px;
  }
  .alert-title {
    font-size: 22px;
    font-weight: 500;
    text-align: justify;
  }
  .alert-wrapper {
    border-radius: 2px;
  }
  .alert-head {
    padding-inline-start: 24px;
    padding-inline-end: 24px;
    padding-top: 24px;
    padding-bottom: 20px;
  }
  .alert-button {
    color: var(--ion-color-primary);
  }
}
.popover-wide {
  .alert-radio-group::-webkit-scrollbar,
  .alert-checkbox-group::-webkit-scrollbar {
    width: 0.7em;
    display: block !important;
  }
  .alert-radio-group::-webkit-scrollbar-thumb,
  .alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: lightgrey;
    outline: 1px solid #fff;
  }

  .alert-radio-label.sc-ion-alert-md {
    white-space: normal;
  }
}
/* Set the icon color and opacity in ion-select*/
ion-select::part(icon) {
  opacity: 0.7 !important;
}
// for currency-surcharge position add data
.currency-surcharge {
  position: absolute;
  color: #ccc;
  font-size: 14px;
  right: 15px;
}
.shortCss {
  .alert-message {
    height: auto !important;
    padding: 0 10px 0 0;
    margin: 0 5px 0 0px;
  }

  .list-styles {
    text-align: justify;
  }
  .fbo-list {
    margin-top: 15px;
    line-height: 1.6;
    padding-inline-start: 5px;
  }
  .element-styles {
    line-height: 1.6;
    padding-inline-start: 5px;
  }
  .list-styles-ovf {
    text-align: justify;
    li:not(:last-child){
      margin-bottom: 1em;
    }
  }
  .rev-element-styles {
    text-align: left;
  }

  .country-styles {
    margin-left: 17px;
  }
}
.tag-style {
  display: inline-block;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  background-color: var(--ion-color-light-tint);
}
.ul-li-style {
  list-style-type: circle;
}
.ul-style {
  padding-inline-start: 20px;
}
.ul-style-pading{
  padding-inline-start: 16px;
}
// for IOS search Size
.searchbar-input.sc-ion-searchbar-ios {
  font-size: 15px !important;
}
.searchbar-cancel-button.sc-ion-searchbar-ios {
  font-size: 15px !important;
}

[top-75] {
  top: 75% !important;
}
// on scroll down segment is fixed
.custom-height {
  height: calc(100vh - 250px);
  overflow: auto;
}
.scroll {
  height: calc(100vh - 265px) !important;
  overflow: auto;
}
[border-width-0] {
  --border-width: 0px;
}
.textCss {
  line-height: 1.5rem;

  .alert-message.sc-ion-alert-md {
    padding-inline-start: 40px;
  }
}
.fl-type-text-wrap {
  ion-select::part(text) {
    position: absolute;
    right: 32px;
  }
}
// for bullet Pagination
.swiper-pagination {
  position: relative !important;
  text-align: center;
  bottom: 0px !important;
  -webkit-transition: 300ms opacity;
  -o-transition: 300ms opacity;
  transition: 300ms opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.ion-tags-input a.iti-tag-rm::before {
  content: " X";
  cursor: pointer;
  color: #e82323 !important;
}
// for schedule card css
.airport-name {
  font-size: 12px;
  padding-top: 0px;
  max-width: 110px;
}
.line-top {
  z-index: -1;
  background: transparent;
  border: none;
  border-top: dashed 2px var(--ion-border-color) !important;
  border-radius: 50%;
  height: 40px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.left-plane {
  float: left;
  transform: rotate(-28deg);
  color: var(--ion-border-color) !important;
}
.right-plane {
  float: right;
  transform: rotate(20deg);
  color: var(--ion-border-color) !important;
}
.plane {
  margin-top: -39px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.distance {
  font-size: 12px;
  padding-bottom: 5px;
  margin-top: -21px;
  color: var(--ion-color-dark);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.flying-time {
  font-size: 12px;
  margin-top: 7px;
  color: var(--ion-color-dark);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.time {
  font-size: 10px;
  color: var(--ion-color-dark);
  margin-left: 5px;
  margin-top: 2px;
}
.scheduleTripStatus {
  margin-top: -5px;
  text-align: center;
  font-size: 12px !important;
  display: flex;
  justify-content: center;
}
.unaviableDatesCss {
  position: absolute;
  top: 18px;
  right: 20px;
}
ion-item {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
  supported by Chrome and Opera */
}
.spinnerText {
  font-weight: lighter !important;
}
// **START** For round image with camera class common
.background-img {
  background-color: var(--ion-background-color) !important;
}
.camera-position {
  z-index: 99999;
  position: absolute;
  background: var(--ion-background-color) !important;
  border-radius: 15px;
  height: 22px;
  width: 22px;
  padding: 2px;
  color: var(--ion-color-icon) !important;
  margin-top: 29px;
  margin-left: -16px;
  font-size: 20px;
}
.client-content {
  display: block;
  width: 90px;
  height: 100px;
  margin: 0 auto 0;
}
.image-upload > input {
  display: none;
}
#client-profile-img {
  display: block;
  border-radius: 100px;
  border: 3px solid double;
  border: 3px solid #e0e0e0;
  width: 90px;
  height: 90px;
  margin: 30px auto 0;
}
.imgs-spinner {
  bottom: 55px !important;
  margin-left: 30px;
}
// **END** For round image with camera class common
// add button icon size
.trips-icon {
  font-size: 30px !important;
}
.soft-update {
  left: 0 !important;
  right: 0;
  margin: auto;
  position: absolute;
  z-index: 10;
  display: block;
  width: 100%;
  max-width: 700px;
  --background: #333;
  --color: #f3f3f3;
}
.expiryDate {
  color: var(--ion-color-red);
}
.upload-button {
  --background: var(--ion-custom-button-bg) !important;
  color: var(--ion-header-text) !important;
}
.custom-popover .popover-content {
  top: 220px !important;
}
.centered-popover .popover-content {
 --width: auto;
 transform-origin: right !important;
 border-radius: 15px !important;
}
.center-popover .popover-content {
  --width: 50%;
  left: 20% !important;
  border-radius: 15px !important;
}
.centre-popover .popover-content {
  --width: 50%;
  left: 20% !important;
  border-radius: 15px !important;
  top: 10% !important;
  bottom: 10%;
}

// wallet common css
.ending-content {
  margin: auto !important;
  max-width: auto;
  span {
    text-align: right !important;
    display: block;
    padding: 0;
    font-weight: 600;
  }
}
[wallet-icon-size]{
  font-size: 25px;
  padding: 0 4px;
}
.trip-container {
  display: flex;
  align-items: center;
}
.ios body ion-header ion-title .trip-all{
  margin: 25px 0px 0px 0px;
}
.ios body ion-header ion-title .trip-container{
  display:block;
}
.ios body ion-header ion-title .header-subheading{
  text-transform: capitalize !important;
  font-weight: bold;
  font-size: initial;
  letter-spacing: normal !important;
  color: #727272;
  padding-bottom: 25px;
  margin: 0px 0px;
}
.trip-all {
  margin-left: 10px;
}
.header-subheading {
  text-transform: capitalize !important;
  font-weight: bold;
  font-size: initial;
  letter-spacing: normal !important;
  color: #727272;
  margin-left: 8px;
  margin-top: 2px;
}
.trip-all-ios{
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: -15px;
}
.header-subheading-ios{
  text-transform: capitalize !important;
  font-weight: bold;
  font-size: initial;
  letter-spacing: normal !important;
  color: #727272;
}
.trip-container-ios {
display:block;
}
  ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
  }
  // End for none icon
/* these css for slider desktop view */
  .custom-item {
    display: flex;
    margin-bottom: 6px;
    margin-top: 5px;
  }
  .web-options {
    display: flex;
    width: 100%;
  }
  .webOptions {
    display: flex;
  }
  // End

  //select text 
  .select-copyText{
    user-select: text; 
    -webkit-user-select: text
  }

  .badge {
    position: absolute;
    left: 17px;
    top: -14px;
    --background-hover-opacity: 0;
    --background-focused-opacity: 0;
  }

  .trip-icon {
    margin-top: 0px;
    padding: 0px;
    font-size: 12px !important;
  }
  .spinner-active::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  ion-button[fill="clear"]:hover {
    --background-hover:transparent;
  }
  