// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #4f5673;
  --ion-color-lightheader: #f6f6ff;
  --ion-color-headertext: #727272;
  --ion-color-light-background: #fdfdff;
  --ion-light-background: #fdfdff;
  --ion-header-light-background: #fafaff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #d8d8e0;
  --ion-color-primary-tint: #f7f7ff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-danger-dark: #6d0505;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-text-color1: #504f4f;
  --ion-text-color2: #214174;
  --ion-text-color3: #dedede;
  --ion-header-text: #727272;
  --ion-light-text: #999;
  --ion-color-amber: #ff8f00;
  --ion-color-green: #2e7d32;
  --ion-color-blue: #5a6388;
  --ion-color-red: #c62828;
  --ion-color-label: #666565;
  --ion-primary-text: #4f5673;
  --ion-color-step-1050: #f4f5f9;
  --ion-color-step-1000: #f9f9f9;
  --ion-color-step-1100: #27ae60;
  --ion-color-step-1150: #ffffff;
  --ion-color-step-1200: #f6f6ff;
  --ion-color-step-1250: #999999;
  --ion-color-step-1300: #dedede;
  --ion-custom-button-bg: #dadaf6;
  --very-light-border: #f1f1f1;
  --ion-color-icon: #6d6d6d;
  --ion-text-background: #efeff4;
  --ion-border-color: #dedede;
  --ion-options-background-color: #fdfdfd;
  --ion-badge-bg-color: #e75973;
  --ion-dark-text-color: #000000;
  --ion-error-msg-text-color: #c0392b;
  --ion-border-color: #ece8e8;
  --ion-header-bg-color: #f6f6ff;
  --ion-color-dark: #9e9c9c;
  --ion-border-very-light: #f1f1f1;
  --ion-icon-color: #a6a6a6;
  --ion-color-card-header: #737373;
  --ion-color-searchbar-input: #141414;
  --ion-background-color: #fff;
  --ion-color-dark-wallet: #000;
  --ion-color-item: #eaeaea;
  --ion-color-estimate: #e4e4e4;
  --ion-color-estimate-header: #efefef;
  --ion-background-pager: #4e5573;
  --ion-custom-font-color: #666666;
  --ion-font-weight: 400 !important;
  --ion-color-listiconcolor: #737373 !important;
  --ion-highlight-background-color-sidemenu: #f2f2f2;
}
@media (prefers-color-scheme: primary) {
  /*
   * Dark Colors
   */
  body {
    --ion-color-primary: #4f5673;
    --ion-color-lightheader: #f6f6ff;
    --ion-color-headertext: #727272;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;
    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;
    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;
    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;
    --ion-color-dark: #797979;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
    --ion-color-light: #999999;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
    --ion-header-bg-color: #f6f6ff;
    --ion-badge-bg-color: #e75973;
    --inner-padding-end: 0px !important;
    --ion-btn-color: #999;
    --ion-color-medium: #949494;
  }
  /*
   * iOS Dark Theme
   * -------------------------------------------
   */
  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }
  /*
   * Material Design Dark Theme
   */
  .ios body,
  .md body {
    --ion-background-color: #f7f7f7;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-border-color: #dedede;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-color-step-1000: #f9f9f9;
    --ion-color-step-1050: #f4f5f9;
    --ion-color-step-1100: #27ae60;
    --ion-item-background: #ffffff;

    --ion-toolbar-background: #f7f7f7;
    --ion-tab-bar-background: #1f1f1f;
    --ion-card-background: #1e1e1e;
    --ion-background: #e6e6e6;
  }
  [background-img] {
    background-color: #fff !important;
    background-repeat: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-size: 100% 100%;
    height: 100%;
  }
  [scroll-overflow] {
    overflow: scroll;
  }
  [logo] {
    position: relative !important;
    top: 40px !important;
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
    line-height: 100px;
    width: 110px;
    height: 110px;
  }
  [auth-input] {
    ion-input {
      ::placeholder {
        color: #cccccc !important;
        font-weight: bold !important;
      }
      :-ms-input-placeholder {
        color: #cccccc !important;
        font-weight: bold !important;
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #cccccc !important;
        font-weight: bold !important;
      }
    }
    ion-item {
      background-color: transparent !important;
    }
    .item > ion-icon:first-child,
    .item-inner > ion-icon:first-child {
      margin-left: 10px;
      text-align: center;
      min-width: 23px;
      color: #515756 !important;
    }
    .item-block:first-child {
      border-top: none !important;
    }
    .item-input:last-child {
      border-bottom: none !important;
    }
    .item-md {
      padding-left: 0px;
    }
    .item-ios {
      padding-left: 0px;
    }
    item-md [item-end] {
      margin: 0px;
    }
    .item-md ion-icon[item-left] + .item-inner,
    .item-md ion-icon[item-left] + .item-input,
    .item-md ion-icon[item-start] + .item-inner,
    .item-md ion-icon[item-start] + .item-input {
      margin-left: 10px;
    }
    .item-md .item-button {
      padding: 0 0.6em;
      height: 25px;
      color: #727272 !important;
      margin-left: 1px !important;
    }
    .item-ios .item-button {
      padding: 0 0.5em;
      height: 24px;
      color: #727272 !important;
      margin-left: 1px !important;
    }
    .item-md ion-icon[item-right],
    .item-md ion-icon[item-start],
    .item-md ion-icon[item-end] {
      margin-left: 10px !important;
      margin-top: 11px;
      margin-bottom: 10px;
    }
    .checkbox-md-secondary .checkbox-checked {
      margin-left: 10px !important;
    }
    // check mark for ios
    .item.item-ios .checkbox-ios {
      position: static;
      display: block;
      margin: 8px 16px 8px 10px;
    }
    .item-ios ion-icon[item-right],
    .item-ios ion-icon[item-start],
    .item-ios ion-icon[item-end] {
      margin-top: 9px;
      margin-bottom: 8px;
    }
    .ion-app.ios [margin-top],
    ion-app.ios [margin-top] .scroll-content {
      margin-top: 16px;
      margin-left: 10px !important;
    }
    .checkbox-ios .checkbox-icon {
      border-radius: 50%;
      position: relative;
      width: 21px;
      height: 21px;
      border-width: 1px;
      border-style: solid;
      border-color: #424242;
    }
    .item.item-md .checkbox-md {
      margin: 9px 24px 9px 4px;
    }
  }
  [scroll-overflow] {
    overflow: scroll;
  }
  [font-color] {
    color: var(--ion-header-text) !important;
  }
  [icon-color] {
    color: var(--ion-header-text) !important;
  }

  [authentication-margin] {
    margin-top: 30px !important;
  }
}
[text1] {
  color: var(--ion-text-color1) !important;
}
[text2] {
  color: var(--ion-text-color2) !important;
}
[text3] {
  color: var(--ion-header-text) !important;
}
[text4] {
  color: var(--ion-light-text) !important;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc !important;
  margin: 1em 0;
  padding: 0;
}
.amber-status,
.green-status {
  font-size: 27px !important;
}
.amber-status {
  color: var(--ion-color-amber) !important;
}
.blue-status {
  color: var(--ion-color-blue) !important;
}
.green-status {
  color: var(--ion-color-green) !important;
}
.red-status {
  color: var(--ion-color-red) !important;
}
.disable-status {
  color: var(--ion-light-text) !important;
}
[font-weight-500] {
  font-weight: 500 !important;
}
[font-weight-bold] {
  font-weight: bold !important;
}
ion-label {
  font-size: 12px;
}
ion-header,
ion-toolbar {
  --background: #f6f6ff !important;
}
[button-margin-none] {
  margin-left: 0px !important;
  margin-right: 0px !important;
  border-radius: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0 !important;
  border-right-width: 0 !important;
  border-left-width: 0 !important;
}
[padding-10] {
  padding: 10px !important;
}
[padding-14] {
  padding: 14px !important;
}
[padding-bottom-10] {
  padding-bottom: 10px !important;
}
[padding-top-10] {
  padding-top: 10px !important;
}
[padding-tp-0] {
  --padding-top: 0px !important;
}
[padding-end-5] {
  --padding-end: 5px;
}
[padding-left-10] {
  padding-left: 10px !important;
}
[padding-left-15] {
  padding-left: 15px !important;
}
[padding-left-5] {
  padding-left: 5px !important;
}
[padding-right-5] {
  padding-right: 5px !important;
}
[padding-right-10] {
  padding-right: 10px !important;
}
[margin-top-10] {
  margin-top: 10px !important;
}
[margin-bottm-10] {
  margin-bottom: 10px !important;
}
[padding-right-4] {
  padding-right: 4px !important;
}
[padding-top-16] {
  padding-top: 16px !important;
}
[padding-0] {
  padding: 0 !important;
}
[padding-left-0] {
  padding-left: 0px !important;
}
[padding-8] {
  padding: 8px !important;
}
[margin-8] {
  margin: 8px !important;
}
[padding-bottom-8] {
  padding-bottom: 8px !important;
}
[padding-top-8] {
  padding-top: 8px !important;
}
[padding-left-8] {
  padding-left: 8px !important;
}
[padding-right-8] {
  padding-right: 8px !important;
}
[margin-right-8] {
  margin-right: 8px !important;
}
[padding-left-16] {
  padding-left: 16px !important;
}
[padding-top-14] {
  padding-top: 14px !important;
}
[margin-left-4] {
  margin-left: 4px !important;
}
[margin-left-5] {
  margin-left: 5px !important;
}
[margin-top-5] {
  margin-top: 5px !important;
}
[padding-bottom-5] {
  padding-bottom: 5px !important;
}
[padding-top-5] {
  padding-top: 5px !important;
}
[padding-inline-2] {
  padding-inline: 2px !important;
}
[padding-left-30] {
  padding-left: 30px !important;
}
[margin-bottom-7] {
  margin-bottom: 7px;
}
[margin-left-13] {
  margin-left: 13px !important;
}
[margin-top-8] {
  margin-top: -8px !important;
}
[margin-top-40] {
  margin-top: 40px !important;
}

[margin-left-20] {
  margin-left: 20px !important;
}
[margin-right-2] {
  margin-right: 2px !important;
}
[margin-right-5] {
  margin-right: 5px !important;
}
[margin-left-10] {
  margin-left: 10px !important;
}
[margin-left-15] {
  margin-left: 15px !important;
}
[margin-bottom-20] {
  margin-bottom: 20px !important;
}
[margin-bottom-50] {
  margin-bottom: 50px !important;
}
[margin-top-7] {
  margin-top: 7px !important;
}
[margin-inline] {
  margin-inline-end: 15px !important;
}
.margin-top-15,
[margin-top-15] {
  margin-top: 15px !important;
}
[ion-fixed-child] {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 999;
}
[font-weight] {
  font-weight: 700 !important;
  color: var(--ion-header-text) !important;
}
.disable-list {
  opacity: 0.6; //This grays it out to look disabled
}
// segment class start
[first-segment] {
  ion-badge {
    padding: 1px 4px !important;
    margin-top: -14px !important;
  }
  background: transparent !important;
  ion-segment-button {
    height: 41px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    max-width: none;
    min-height: 41px !important;
    color: var(--ion-header-text) !important;
    --indicator-color: transparent !important;
    --indicator-color-checked: none !important;
    --border-radius: none;
  }
  .segment-button-checked {
    background: transparent !important;
    color: var(--ion-color-primary);
    border-bottom: 1px solid var(--ion-header-text) !important;
    --indicator-box-shadow: none;
  }
}
[second-segment] {
  ion-segment-button {
    min-height: 41px !important;
    height: 41px !important;
  }
  .in-segment {
    border: 1px solid var(--ion-header-text) !important;
    color: var(--ion-header-text) !important;
  }
}
[active-segment] {
  --background: transparent !important;
  ion-segment-button {
    max-width: none;
    --indicator-color: transparent !important;
    --indicator-color-checked: none !important;
    --background-checked: #f6f6ff !important;
    --background-focused: none;
    --background-hover: none;
    --border-radius: none;
  }
  ion-segment-button:first-of-type {
    --border-radius: 4px 0 0 4px !important;
  }
  ion-segment-button:last-of-type {
    border-radius: 0 4px 4px 0 !important;
  }
}
// for ios middle segment border
[middle-segment] {
  border-radius: inherit !important;
}
ion-segment-button:hover {
  --background-focused: none;
  --background-hover: none;
  color: var(--ion-header-text) !important;
}
ion-segment-button ion-label {
  letter-spacing: normal !important;
  font-size: 12px !important;
}
// segment class end
// start all activity card theme
[activity-card] {
  height: 110px !important;
  margin-top: 20px !important;
  border-radius: 15px !important;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.14),
    0 11px 1px -10px rgba(0, 0, 0, 0.2), 0 -1px 0px 0 rgba(0, 0, 0, 0.12) !important;
  background: var(--ion-light-background) !important;
  ion-row span {
    color: var(--ion-header-text) !important;
  }
  ion-row ion-col span {
    color: var(--ion-primary-text) !important;
  }
  ion-row ion-col {
    margin-top: 10px !important;
  }
  progress {
    margin-top: 5px !important;
  }
}
[input-errormsg] {
  padding-top: 5px !important;
  color: var(--ion-color-danger-dark) !important;
  font-size: 12px !important;
  margin-left: 70px !important;
}
ion-item {
  color: var(--ion-header-text) !important;
}
.spinner-lines.sc-ion-loading-md,
.spinner-lines-small.sc-ion-loading-md,
.spinner-bubbles.sc-ion-loading-md,
.spinner-circles.sc-ion-loading-md,
.spinner-crescent.sc-ion-loading-md,
.spinner-dots.sc-ion-loading-md {
  --color: none !important;
}
[margin-inline] {
  margin-inline-end: 20px !important;
}
[overflow-lable] {
  overflow: visible !important;
}
[margin-left-240] {
  margin-left: 240px !important;
}
[margin-right-10] {
  margin-right: 10px !important;
}
[margin-left-25] {
  margin-left: 25px !important;
}
[quick-icon-color] {
  color: var(--ion-icon-color) !important;
}
.swiper-pagination-bullet-active {
  background-color: (--ion-background-pager) !important;
}
ion-slides {
  --bullet-background: #000 !important;
}
[no-boxshadow] {
  box-shadow: none !important;
}
.select-radio-icon {
  font-size: 2.4rem;
  padding-right: 8px;
}
.no-display {
  display: none;
}
.trip-srid-design {
  align-self: center;
  align-content: center;
  width: 17%;
  margin: inherit;
  text-align: center;
  margin-right: 10px;
}
[no-margin] {
  margin: 0px !important;
}
.position-data {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  color: var(--ion-primary-text) !important;
}
[text-round-input] {
  border: 1px solid #f1f1f1 !important;
  border-radius: 7px !important;
}
[selected-item] {
  background: var(--ion-color-primary-contrast) !important;
  width: 100% !important;
  border-bottom: 1px solid !important;
  border-bottom: var(--ion-border-color) !important;
  padding-left: 10px !important;
  #round {
    background: #e6e6e6 !important;
    margin-top: 5px !important;
    margin-left: 5px !important;
    margin-bottom: 5px !important;
    height: 37px !important;
    display: inline-block !important;
    border-radius: 20px !important;
    padding-left: 10px !important;
  }
  ion-icon {
    margin-left: 5px !important;
    padding: 10px 5px 0px !important;
  }
}
// code for segment scrolling with width
[segment-min-width-50] {
  min-width: 50% !important;
}
.ios-segment-width-intab {
  padding: 1rem;
}
[border-style] {
  --border-style: none;
}
ion-segment {
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar-corner {
    width: 0;
    height: 0;
    display: none;
  }
  ion-segment-button.segment-button {
    flex: 1 0 115px;
    --color-selected: var(--ion-header-color);
  }
}
ion-loading.custom-loading {
  .loading-wrapper {
    background: transparent !important;
    box-shadow: none !important;
  }
}
ion-loading.backgroud-box {
  .loading-content {
    color: var(--ion-header-text) !important;
  }
}
[listiconcolor] {
  color: var(--ion-color-listiconcolor);
}
// light header color we can use direct in buttons  Ex" color="lightheader"
.ion-color-lightheader {
  --ion-color-base: var(--ion-color-lightheader) !important;
  --ion-color-base-rgb: var(--ion-color-lightheader-rgb) !important;
  --ion-color-contrast: var(--ion-color-lightheader-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-lightheader-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-lightheader-shade) !important;
  --ion-color-tint: var(--ion-color-lightheader-tint) !important;
}
.ion-color-headertext {
  --ion-color-base: var(--ion-color-headertext) !important;
}
.ion-color-headerbackground {
  --ion-color-base: var(--ion-custom-button-bg) !important;
}
:host {
  --letter-spacing: normal !important;
}
[info-icon] {
  z-index: 1;
  position: absolute;
  padding-left: 10px;
  top: 9px;
  font-size: 2rem;
  color: var(--ion-color-primary);
}
.add-ranges {
  padding-inline-start: 22px !important;
  margin-top: 5px !important;
  font-size: 14px !important;
}
.range-col {
  font-size: 20px !important;
  color: var(--ion-color-primary) !important;
  height: 40px !important;
}
.date-ranges {
  color: #4f5673 !important;
  background-color: #dedede !important;
  border-radius: 6px !important;
  margin-inline-end: 5px;
  display: inline-block;
  height: 30px;
  padding: 5px;
  margin-top: 5px;
}
.custom-tel-input{
 ionic-selectable-modal > ion-header > ion-toolbar > ion-title {
  padding-left: 25px !important;
}
}
.custom-tel-input-platform {
  ionic-selectable-modal > ion-header > ion-toolbar > ion-title {
    padding-left: 0px !important;
    text-align: center;
    padding-right: 0px !important;
  }
}
/* CSS file for web Blink*/
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* WebKit/Blink browsers */
  margin: 0; /* Removes default margin */
}
